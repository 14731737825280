<template>
  <div class="index">
    <!-- banner -->
    <div class="banner">
      <Swiper :data="lbts" />
      <Navigator />
    </div>
    <!-- banner -->

    <!-- 产品和服务web -->
    <div class="productWeb">
      <p>我们的产品与服务</p>
      <SwiperWeb />
    </div>
    <!-- 产品和服务web -->

    <!-- 产品和服务PC -->
    <div class="product">
      <p>我们的产品与服务</p>
      <span>以茶产业数字化转型为战略核心，同时连接其它领域协同发展</span>
      <div class="productImg">
        <template v-for="(item, i) in productImg">
          <a :key="i" @click="to(i)">
            <!-- :style="{ background: 'url(' + item.imgSrc + ')' }" -->
            <img
              :src="item.imgSrc1"
              alt=""
              :data-value="i"
              @mouseover="mouseoverFn"
              @mouseout="mouseoutFn"
            />
            <i>{{ item.text }}</i>
          </a>
        </template>
      </div>
    </div>
    <!-- 产品和服务 -->

    <div class="official-video">
      <video
        src="http://jsxygkj.oss-cn-hangzhou.aliyuncs.com/mp4/xyg_official_1.mp4"
        alt="秀圆果宣传视频"
        class=""
        controls
        poster="http://jsxygkj.oss-cn-hangzhou.aliyuncs.com/mp4/xyg_official_1.jpg"
      />
    </div>

    <!-- 内容 -->
    <Content />
    <!-- 内容 -->
    <!-- 服务 -->
    <div class="service">
      <p>其它产品</p>
      <span>基于RFID提供个性化周边服务产品</span>
      <div class="serviceImg">
        <template v-for="(item, i) in serviceImg">
          <div :key="i">
            <img :src="item.imgSrc" alt="" />
            <h3>{{ item.title }}</h3>
            <i>{{ item.text }}</i>
          </div>
        </template>
      </div>
    </div>
    <!-- 服务 -->
    <!-- 底部 -->
    <Footer />
    <!-- 底部 -->
  </div>
</template>

<script>
export default {
  components: {
    Swiper: () => import("./component/Swiper"),
    SwiperWeb: () => import("./component/SwiperWeb"),
    Content: () => import("./component/Content"),
  },
  data() {
    return {
      lbts: [
        {
          img: require("@/assets/img/banner4.jpg"),
          title: "秀圆果科技",
          text: "数字化转型集成服务商",
        },
        {
          img: require("@/assets/img/banner1.jpg"),
          title: "茶产业数字化转型综合服务商",
          text: "秀圆果“溯享云”科技，助力茶产业数字化转型",
          imgBj: require("@/assets/img/Component3.png"),
        },
        {
          img: require("@/assets/img/banner2.jpg"),
          title: "助力企业智改数转",
          text: "十年RFID技术研发，铸就专业领先实力",
          imgBj: require("@/assets/img/banner0.png"),
        },
        {
          img: require("@/assets/img/banner3.jpg"),
          title: "助力快消品企业数字化品牌化",
          text: "十年RFID技术研发，铸就专业领先实力",
        },
      ],
      productImg: [
        {
          imgSrc1: require("@/assets/img/imgSrc1.png"),
          imgSrc2: require("@/assets/img/imgSrc11.png"),
          text: "茶企数字化整套解决方案",
        },
        {
          imgSrc1: require("@/assets/img/imgSrc2.png"),
          imgSrc2: require("@/assets/img/imgSrc22.png"),
          text: "品牌赋能、渠道建设",
        },
        {
          imgSrc1: require("@/assets/img/imgSrc3.png"),
          imgSrc2: require("@/assets/img/imgSrc33.png"),
          text: "链接政企，服务社会",
        },
        {
          imgSrc1: require("@/assets/img/imgSrc4.png"),
          imgSrc2: require("@/assets/img/imgSrc44.png"),
          text: "智慧物联赋能数字工厂建设",
        },
        {
          imgSrc1: require("@/assets/img/imgSrc5.png"),
          imgSrc2: require("@/assets/img/imgSrc55.png"),
          text: "新冠接种、儿童预防接种",
        },
      ],
      serviceImg: [
        {
          imgSrc: require("@/assets/img/rectangle5.png"),
          title: "电子内飞",
          text: "一种基于RFID的电子内飞（专利号：2017210315878）",
        },
        {
          imgSrc: require("@/assets/img/rectangle1.png"),
          title: "秀圆查",
          text: "基于溯享平台的RFID防伪溯源通用查询工具",
        },
        {
          imgSrc: require("@/assets/img/rectangle2.jpg"),
          title: "秀圆芯",
          text: "为企业和个人提供基于RFID展示内容的私人定制",
        },
        {
          imgSrc: require("@/assets/img/rectangle3.jpg"),
          title: "茶盲盒",
          text: "茶叶盲盒产品，是茶店营销和产品推广利器",
        },
      ],
    };
  },
  methods: {
    to(value) {
      console.log(value);
      switch (value) {
        case 0:
          this.$router.push({ path: "/tea" });
          break;
        case 1:
          this.$router.push({ path: "/health" });
          break;
        case 2:
          this.$router.push({ path: "/society" });
          break;
        case 3:
          this.$router.push({ path: "/industry" });
          break;
        case 4:
          this.$router.push({ path: "/hygiene" });
          break;

        default:
          break;
      }
    },
    mouseoverFn(e) {
      // console.log(e.target);
      let value = e.target.getAttribute("data-value");
      let temp = e.target;
      temp.src = this.productImg[value].imgSrc2;
    },
    mouseoutFn(e) {
      let value = e.target.getAttribute("data-value");
      let temp = e.target;
      temp.src = this.productImg[value].imgSrc1;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .index {
    .product {
      display: none;
    }
    .productWeb {
      display: block;
      text-align: center;
      p {
        font-size: 14px;
        font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
        font-weight: 400;
        color: #093c7f;
        line-height: 20px;
        margin: 13px 0 10px;
      }
    }
    .service {
      // height: 8.12rem;
      // text-align: center;
      margin: 0.44rem 0 1rem;
      p {
        // width: 5.14rem;
        height: 1.1rem;
        font-size: 0.64rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #093c7f;
        line-height: 0.75rem;
        margin-left: 7.03rem;
      }
      span {
        text-align: center;
        display: block;
        height: 0.37rem;
        font-size: 0.2rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 0.23rem;
        // margin-left: 7.14rem;
        margin-top: -0.18rem;
      }
      .serviceImg {
        display: flex;
        margin-top: 1.04rem;
        margin-left: 1.24rem;
        div {
          width: 3.95rem;
          margin-right: 0.3rem;
          img {
            width: 3.95rem;
            height: 2.75rem;
            z-index: 2;
          }
          h3 {
            // width: 1.9rem;
            margin-bottom: 0.3rem;
            height: 0.42rem;
            font-size: 0.3rem;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #26488e;
            line-height: 0.35rem;
            letter-spacing: 0.16rem;
            margin-top: 0.18rem;
          }
          i {
            width: 3.95rem;
            height: 0.65rem;
            font-size: 0.2rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 0.23rem;
            margin-top: 0.09rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .productWeb {
    display: none;
  }
  .product {
    text-align: center;
    width: 100%;
    height: 6.52rem;
    text-align: center;
    overflow: hidden;
    p {
      width: 5.14rem;
      height: 1.1rem;
      font-size: 0.64rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #093c7f;
      line-height: 0.75rem;
      margin-left: 7.03rem;
      margin-top: 0.44rem;
    }
    span {
      display: block;
      height: 0.37rem;
      font-size: 0.2rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 0.23rem;
      margin-top: -0.18rem;
    }
    .productImg {
      display: flex;
      margin: 0 1rem;
      justify-content: space-around;
      a {
        display: block;
        width: 3rem;
        height: 4rem;
        cursor: pointer;
        img {
          width: 3rem;
          height: 4rem;
        }
        i {
          text-align: center;
          margin-top: -1rem;
          display: block;
          font-size: 0.2rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
      }
    }
  }
  .service {
    // text-align: center;
    margin-bottom: 1rem;
    p {
      // width: 5.14rem;
      text-align: center;
      height: 1.1rem;
      font-size: 0.64rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #093c7f;
      line-height: 0.75rem;
      margin-top: 0.44rem;
    }
    span {
      text-align: center;
      display: block;
      height: 0.37rem;
      font-size: 0.2rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 0.23rem;
      // margin-left: 7.14rem;
      margin-top: -0.18rem;
    }
    .serviceImg {
      display: flex;
      margin-top: 1.04rem;
      margin-left: 1.24rem;
      div {
        width: 3.95rem;
        // height: 3.75rem;
        margin-right: 0.3rem;
        img {
          width: 3.95rem;
          height: 2.75rem;
          z-index: 2;
        }
        h3 {
          // width: 1.9rem;
          height: 0.42rem;
          font-size: 0.3rem;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #26488e;
          line-height: 0.35rem;
          letter-spacing: 0.16rem;
          margin-top: 0.18rem;
        }
        i {
          width: 3.95rem;
          height: 0.65rem;
          font-size: 0.2rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 0.23rem;
          margin-top: 0.09rem;
        }
      }
    }
  }
}
.index {
  width: 100%;
  height: 100%;
  .banner {
    // height: 100%;
    width: 100%;
    background-color: #ccc;
    margin: auto;
    position: relative;
    background: url("../../assets/img/banner0.png") no-repeat center;
  }
  .official-video {
    width: 100%;
    video {
      width: 100%;
    }
  }
}
// }
</style>
